import { Broker } from '@amzn/amazonmq-opsconsole-client';
import { Box, Button, Link, Modal, SpaceBetween, Spinner, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { updateBrokerState } from '../../api/api';

type Props = {
    broker: Broker
}

const BrokerNameComponent : React.FC<Props> = ({broker}) => {
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const brokerSummary = broker.summary;

    function isCanChangeToAvailable(brokerState: string) {
        return ["RESTART_FAILED"].includes(brokerState);
    }

    function onUpdateBrokerStateClicked() {
        setLoading(true);
        setSuccessMessage("");
        setErrorMessage("");
        updateBrokerState(broker.id, "AVAILABLE").then(response => {
            setLoading(false);
            broker.summary.brokerState = "AVAILABLE";
            setSuccessMessage("Broker state updated");
        }).catch(error => {
            console.error(error);
            setErrorMessage("Could not update broker state");
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <Modal
                onDismiss={() => setConfirmationDialogOpen(false)}
                visible={confirmationDialogOpen}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => {
                                setConfirmationDialogOpen(false)
                            }}>Cancel</Button>
                            <Button variant="primary" onClick={() => {
                                onUpdateBrokerStateClicked();
                                setConfirmationDialogOpen(false);
                            }}>Ok</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Confirmation"
                >
                Are you sure you want to update broker state to AVAILABLE?
            </Modal>
            <SpaceBetween size='xs' direction='vertical'>
                {brokerSummary.brokerState === 'AVAILABLE' ? <span>{brokerSummary.name} <StatusIndicator type='success'>{brokerSummary.brokerState}</StatusIndicator></span>
                    : brokerSummary.brokerState.indexOf('FAILED') >= 0 ? <span>{brokerSummary.name} <StatusIndicator type='error'>{brokerSummary.brokerState}</StatusIndicator></span>
                    : <span>{brokerSummary.name} <StatusIndicator type='info'>{brokerSummary.brokerState}</StatusIndicator></span>
                }
                {loading && <SpaceBetween direction='horizontal' size={'xs'}>Updating broker state... <Spinner /></SpaceBetween>}
                {errorMessage && <StatusIndicator type='error'>{errorMessage}</StatusIndicator>}
                {successMessage && <StatusIndicator type='success'>{successMessage}</StatusIndicator>}
                {!loading && isCanChangeToAvailable(broker.summary.brokerState) &&
                    <Link onClick={() => {
                        setConfirmationDialogOpen(true);
                    }}>Set to available</Link>
                }
            </SpaceBetween>
        </>
    )
}

export default BrokerNameComponent;